const mail_list = {
  FMfcgzQVwnRqWPbpgWRQskRWfDKXbCpg: {
    sender: `Stil Archaïque`,
    sender_mail: `promote@stilarchaique.com`,
    title: `Holly, Waiting for you!`,
    body: `
      <p>World-renowned designer 'Holly'!</p>
      <p>I'm sending you an invitation to the brand launch party in Paris, France.</p>
      <p>Designers and celebrities from all over the world will participate in the party.</p>
      <p>I hope 'Holly' will also shine here and look forward to it.</p>
    `,
  },
  aBfjweqRV2SADpqwWE94FMVcQqP18SAm: {
    sender: `Gong-hee Maeng`,
    sender_mail: `design.gonghee@jayce.com`,
    title: `Collaboration Proposal for 2024 S/S Seoul Fashion Week`,
    body: `
      <p>Dear Holly,</p>
      <p></p>
      <p>I hope this email finds you well.</p>
      <p>My name is Gong-hee Maeng, and I am writing to you on behalf of JAYCE Fashion.</p>
      <p>We are huge admirers of your unique and evocative designs, and we believe your creative vision aligns perfectly with the direction of our upcoming 2024 Seoul Fashion Week.</p>
      <p>We would be honored to explore a collaboration between you and JAYCE Fashion for this collection,</p>
      <p>as we think your innovative approach would bring fresh energy to our brand and captivate audiences at the upcoming Fashion Week.</p>
      <p>Please let us know if you would be interested in discussing this opportunity further.</p>
      <p>We look forward to the possibility of creating something extraordinary together.</p>
      <p></p>
      <p>Warm regards,</p>
      <p>Gong-hee Maeng</p>
      <p>JAYCE Fashion Design Team</p>
    `,
  },
};

const inbox_list = [
  {
    sender: mail_list[`aBfjweqRV2SADpqwWE94FMVcQqP18SAm`].sender,
    title: mail_list[`aBfjweqRV2SADpqwWE94FMVcQqP18SAm`].title,
    unread: 1,
    id: `aBfjweqRV2SADpqwWE94FMVcQqP18SAm`,
  },
  {
    sender: `invite@fashionparis.com`,
    title: `Exclusive Invitation: Paris Fashion Show Guest Appearance`,
    unread: 1,
  },
  {
    sender: `Magazine FV`,
    title: `Feature Request: Vogue's Designer Spotlight Interview`,
    unread: 1,
  },
  {
    sender: `NY Fashion`,
    title: `New York Fashion Week: Collaboration with Top Designers`,
    unread: 1,
  },
  {
    sender: `XYZ`,
    title: `Partnership Opportunity with Luxury Brand XYZ`,
    unread: 1,
  },
  {
    sender: `DasCafe`,
    title: `Limited Edition Capsule Collection Proposal`,
    unread: 1,
  },
  {
    sender: `contact.DesignLON`,
    title: `Request for Keynote Speech at London Design Conference`,
    unread: 1,
  },
  {
    sender: `invite@milanfashiongala.it`,
    title: `VIP Guest Invitation: Milan Fashion Gala`,
    unread: 1,
  },
  {
    sender: `Elle Magazine`,
    title: `Editorial Collaboration: Elle Magazine Feature`,
    unread: 1,
  },
  // add 2024-10-21
  {
    sender: `Stylist Jane `,
    title: `I would like to request a Holly design`,
    unread: 0,
  },
  {
    sender: `Canel `,
    title: `I would like to request a collaboration Canel`,
    unread: 0,
  },
  {
    sender: `Artist pamta.C `,
    title: `Holly I want to work with you! Contact me!!`,
    unread: 0,
  },
  {
    sender: `Alex huter`,
    title: `Popstar Beyond Design Commission`,
    unread: 0,
  },
  {
    sender: `D.G `,
    title: `I would like to request a concert costume.`,
    unread: 0,
  },
  {
    sender: `Lovely_B `,
    title: `Dear Holly I would like to ask you for clothes please reply.`,
    unread: 0,
  },
  {
    sender: `G.O_jang `,
    title: `Hello Holly, would you like to collaborate on the design for next season?`,
    unread: 0,
  },
  {
    sender: `Director Pan `,
    title: `Where is Holly now? Call me!`,
    unread: 0,
  },
  {
    sender: `Manager Charlie `,
    title: `Holly, the fabric you mentioned last time is in stock, please contact me.`,
    unread: 0,
  },
  {
    sender: `Manager Megan`,
    title: `How is the design for next season going? Please reply.`,
    unread: 0,
  },
  {
    sender: `Stylist Kane Willey `,
    title: `Where are you now? If you see the Holly mail, please contact me.`,
    unread: 0,
  },
  {
    sender: `Manager G`,
    title: `Holly, I was told that the clothes set you are looking for is available at the Royal Department Store in Korea.`,
    unread: 0,
  },
  {
    sender: mail_list[`FMfcgzQVwnRqWPbpgWRQskRWfDKXbCpg`].sender,
    title: mail_list[`FMfcgzQVwnRqWPbpgWRQskRWfDKXbCpg`].title,

    // unread: 1,
    id: `FMfcgzQVwnRqWPbpgWRQskRWfDKXbCpg`,
  },
  {
    sender: `olivia@patrodesign.com`,
    title: `Holly please reply!!`,
  },
  {
    sender: `Bundle Choice`,
    title: `You've skipped a month of Choice`,
  },
  {
    sender: `PED.Emma@ped.com`,
    title: `Dear Holly, With love`,
  },
  {
    sender: `Friday Facts`,
    title: `Friday Facts #431 - Gleba & Captivity`,
  },
  {
    sender: `contact@armandfashion.com`,
    title: `This is Armand Fashion Company. Please contact me. Holly`,
  },
  {
    sender: `Gomma`,
    title: `We've updated our Privacy Policy`,
  },
  {
    sender: `service@intl.paykan`,
    title: `Hello PayKan, haven't you applied for US$3 rewards yet?`,
  },
  {
    sender: `contact@frenchfashion.fr`,
    title: `This is the French fashion magazine Roem.`,
  },
  {
    sender: `Coxabay`,
    title: `New tools! Lets go 🎶`,
  },
  {
    sender: `isabella@legoo.site`,
    title: `I would like to request a design, Holly`,
  },
  {
    sender: `PenCode`,
    title: `[PenCode Spark] Green Eggs and Jam - View this issue on PenCode Green Eggs and Jam`,
  },
  {
    sender: `PayKan`,
    title: `We've changed some of the PayKan terms and conditions that apply to you.`,
  },
  {
    sender: `int-jone@magazine-d.com`,
    title: `I would like to interview Design Holly.`,
  },
  {
    sender: `Design Volle`,
    title: `Artist Holly, please reply.`,
  },
  {
    sender: `PenCode`,
    title: `Illuminated Text, Fading Perspective, and Styled Progress`,
  },
  {
    sender: `Quad Design`,
    title: `Patrick Arthur's beloved designer Holly`,
  },
  {
    sender: `design.christopher@pecucompany.com`,
    title: `To Designer Holly`,
  },
  {
    sender: `Bundle Choice`,
    title: `This Week at Choice: Movin' and Shakin' VR fun!`,
  },
  {
    sender: `VIDIA`,
    title: `Racle Accelerates AI and Data Processing With VIDIA`,
  },
  {
    sender: `TERV`,
    title: `Commissioned by designer Holly.`,
  },
  {
    sender: `GSReplay.net`,
    title: `[GSReplay.net] Confirm your email address`,
  },
  {
    sender: `Bokuyo`,
    title: `[Final Notice] Usage-based billing will be enable for your subscription`,
  },
];

const inbox_list_clean = [
  {
    sender: mail_list[`FMfcgzQVwnRqWPbpgWRQskRWfDKXbCpg`].sender,
    title: mail_list[`FMfcgzQVwnRqWPbpgWRQskRWfDKXbCpg`].title,
    id: `FMfcgzQVwnRqWPbpgWRQskRWfDKXbCpg`,
    unread: 1,
  },
  {
    sender: ``,
    title: `Holly please reply!!`,
  },
  {
    sender: ``,
    title: `Dear Holly, With love`,
  },
  {
    sender: ``,
    title: `This is Armand Fashion Company. Please contact me. Holly`,
  },
  {
    sender: ``,
    title: `This is the French fashion magazine Roem.`,
  },
  {
    sender: ``,
    title: `I would like to request a design, Holly`,
  },
  {
    sender: ``,
    title: `I would like to interview Design Holly.`,
  },
  {
    sender: ``,
    title: `Artist Holly, please reply.`,
  },
  {
    sender: ``,
    title: `Patrick Arthur's beloved designer Holly`,
  },
  {
    sender: ``,
    title: `To Designer Holly`,
  },
  {
    sender: ``,
    title: `Commissioned by designer Holly.`,
  },
];

export { inbox_list_clean, inbox_list, mail_list };
