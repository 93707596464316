import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import ErrorPage from './components/ErrorPage/ErrorPage';
import Home from './components/Home';
import Result from './components/Result';
import WebHard from './components/WebHard/WebHard';
import Mail from './components/Mail/Mail';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: "/search",
        element: <Result />,
      }
    ]
  },
  {
    path: "/drive",
    element: <WebHard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/mail",
    element: <Mail />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/mail/:mailId",
    element: <Mail />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
