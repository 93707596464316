const search_result_0 = [
  {
    title: "강선우 감독 여자친구 캐스팅 특혜 의혹, 들불처럼 번지는 분노",
    body: "영화 히든 메모리 캐스팅 의혹에 대해 누리꾼들이 분노하고 있다. " +
          "강선우 감독의 여자친구가 불공정한 방법으로 히든 메모리에 발탁됐다는 의혹 기사가 보도됐기 때문이다. " +
          "히든 메모리의 제작사 청필름 측이 즉각 반박 입장문을 발표했지만 누리꾼들의 의심과 분노는 가라앉지 않고 있다. " +
          "강선우 감독이 여자친구의 캐스팅을 강행할 경우, 캐스팅 공정성 의혹이 더욱 커질 것으로 예상된다.",
  },
  {
    title: "히든 메모리 불공정 오디션 논란 갈수록 커져",
    body: "영화 히든 메모리의 오디션 특혜 논란이 사그라들지 않고 있다." +
          "논란의 발단은 강선우 감독의 여자친구 Y 씨가 특혜를 받고 영화에 캐스팅됐다는 보도였다." +
          "이에 히든 메모리 제작사 청필름은 반박문을 내고 \"Y 씨가 공정하게 오디션을 통해 캐스팅됐다\"라고 해명했지만, 여론은 여전히 싸늘한 상태다." +
          "두 사람의 다정한 사진을 본 한 누리꾼은 \"영화계의 공정성을 심각하게 훼손한 사안\"이라며 Y 씨의 하차를 요구했고" + 
          "또 다른 누리꾼은 \"Y 씨의 하차가 영화계의 낙하산 캐스팅 관행을 개선하는 계기가 되길 바란다\"라고 말했다.",
  },
  {
    title: "강선우 감독, 부정 캐스팅 의혹에도 여자친구 출연 강행하나?",
    body: "영화감독 강선우가 여자친구 Y 씨를 자신의 영화 히든 메모리에 캐스팅한 사실이 알려지면서 논란에 휩싸였다. " +
          "히든 메모리 제작사 청필름은 “Y 씨는 연기력과 열정이 뛰어난 배우”라며 “공정하게 오디션을 통해 캐스팅됐다”라고 입장문을 발표했지만 여론은 여전히 냉랭하다." +
          "대부분의 누리꾼들은 Y 씨의 캐스팅 과정에 사적 친분에 의한 특혜 가능성을 완전히 배제할 수 없다며 믿지 못하는 분위기다. " +
          "Y 씨의 캐스팅 의혹을 해소하기 위해서는 Y 씨의 연기력에 대한 객관적인 평가와 캐스팅 과정에 대한 투명한 공개가 필요하다.",
  },
];

const search_result_1 = [
  {
    title:"화이트 가든 - 브런치, 에그베네딕트 맛집 | 다이닝코드, 빅데이...",
    body:"10.07 (토) 영업시간: 09:30 - 20:30 스테이크 세트(55,500원), 브런치 B 세트(46,900원), 푸짐한 철판 토마토 오믈렛 라이스(15,900원), 리코타 샐러드 피자",
  },
  {
    title:"[화이트 가든] 아이랑 가기 좋은 브런치 다이닝 카페",
    body:"얼마 전 엄마와 함께 다녀온 화이트 가든 브런치 다이닝 카페-:) 브런치 카페는 대부분 아이들 어린이집 보내고 아기 엄마들 만날 때 종종 가곤 했는데 엄마랑은 브런치카페 같은... 5 브런치 다이닝 카페는 식사 메뉴뿐 아니라 다양한 음료도 팔고 있고 계산할 때 카운터에",
  },
  {
    title:"믿고먹는 대표 브런치 맛집 화이트 가든",
    body:"' 라는 말에 출발한 화이트 가든이다 완정역에서 나와서 조금 걷다보면 나오는 화이트 가든 지도에는 1층이라고 나와있지만... 아무래도 브런치를 먹으러 올 때 아이가 있는 경우에는 불편할 수 있는데 화이트 가",
  },
  {
    title:"다이닝까지 가능한 브런치 카페 화이트 가든",
    body:"… 화이트 가든 · 다이닝까지 가능한 분위기 좋은 브런치 카페 · 주소 : 인천시 서구 원당대로 638 · 영업시간 : 매일 09:30 - 20:30... 와이프에게 화이트 가든이 무슨 뜻이냐고 물어보니 사랑하는 사람은 체온이 1도가 더 높은데",
  },
  {
    title:"화이트 가든 [가을비 내리는 날의 브런치]",
    body:"마지막은 화이트 가든 떡볶이 아란치니(?)가 올라가 있다 덕분에 고급진 비주얼의 떡볶이다 아란치니 속 해물 씹히는 맛도 좋고 바삭바삭... 있고 대로변에 있어서 찾기도 수월해서 접근성은 좋다 #화이트 가든 #브런치카페 #",
  },
];

const search_result_2 = [
  {
    title:"평택시, 치매안심마을 조성 및 안전망 구축 협약 | 한산일보",
    body:"최종길 기자, 경기 평택시 치매안심센터는 20일 청북농협지점 대회의실에서 현곡리 치매안심마을 운영위원, 유승영 시의원, 청북중학교 교장, 현곡1·2리 어르신, 안심마을 지킴이 대표 등 100여 명이 참석한 가운데 평택치매안심마을사업 설명회 및 치매 안심망 구축 협약식을 개최했다",
  },
  {
    title:"'옥상 위에 잿더미 가득'…평택 냉동창고 화재 여파",
    body:"오후 경기도 평택시 청북읍 인근의 한 농장. 전날 불이 난 냉동창고 공사현장에서 50m도 채 떨어지지 않은 곳이다. 공장 바로 옆에서 농장을 운영하는 최모(65)씨는 긴장한 채로 밤을 보냈다. 이날 새벽 마을이장으로부터 '대피하라'는 전화를 받았다. 공장 관계자들에게는 '화재 때문에 냄새가 날 수 있다'는 연락도 왔다.",
  },
  {
    title:"중부지방 또 다시 폭우, 곳곳이 물난리",
    body:"하늘이 뚫린듯 쏟아진 장맛비로 28일 평택과 안성의 하천 제방 일부가 붕괴되는 등 경기 남부와 충북 북부 지방이 큰 물난리를 겪었다. 전날에 이어 이틀째 장대비가 계속되면서 평택시의 통복천과 안성시의 안성천 제방 일부가 빗물에 쓸려나가 주민 수백명이 긴급 대피했고, 경기와 충북에서는 모두 5명이 숨지거나 실종됐다. 또 곳",
  },
  {
    title:"종교단체 신도9명 살해 암매장",
    body:"신도 실종 및 사기 사건 등으로 물의를 빚어온 한 종교단체가 배교(背敎) 등의 이유로 신도를 살해한 뒤 암매장한 것으로 드러났다. 검찰은 또 살해된 신도가 무려 9명에 이르고 사체를 전국에 암매장했다는 진술을 확보해 수사를 확대하고 있다. ◇수사=수원지검 강력부(부장검사 이지수)는 14일 부천 ㅇ종교단체 신도 김모(66)·정모(44)씨 등 2명에 대해 살인혐의로 구속영장을 청구했다. 검찰은 또 이 종교단체 교주 ㅈ씨(72)를 이날 오전 10시30분 김포공항에서 붙잡아 살인교사혐의로 긴급",
  },
  {
    title:"대구 ‘합동북’ 이호창 사장의 ‘헌책방 人生 30년’",
    body:"‘다 사라진 건 아닐까?’ 갑자기 헌책방이 궁금해졌다. 수소문을 해봤다. 대구의 경우 남문시장 근처에 4곳(코스모스·해바라기·월계·대도서점). 시청 근처에 5곳(규장각·대륙·평화·동양·제일서적)만 남아 있었다. 80년대 한창때는 도로 양옆에 즐비했던 대구역 지하도 헌책방은 흔적도 없이 사라졌고, 그 자리는 중국공예품 등 골동품 가게로 변해버렸다. 외곽지의 경우 북구 대현동 경대교 근처에 있는 합동북이 유일했다. ‘전멸’이라고 해도 과언이 아니었다. 헌책방이 ‘빙하기’에 들어선 것이",
  },
  {
    title:"[홍유상의 경매이야기]경매물건 급증.. 실수요자에겐 적극적인 입찰기회",
    body:"서울과 수도권의 전세 값 급등으로 인해 실수요자들의 경매투자에 대한 관심과 열기가 뜨겁다. 부동산 시장의 침체로 빚에 허덕이는 하우스 푸어들의 주택들이 법원경매에 부쳐지는 비율이 점점 높아지고 있는 것이다. 부동산 경기가 좋았을 때 대출을 많이 받아 집을 샀다가 매매 실종으로 늘어나는 이자를 감당하지 못하고 경매로 넘어오는 물건들이 많다. 전세대란은 내년 봄 이사철 때 까지도 이어질 전망이다. 중.소형 아파트나 빌라는 2회 유찰 후에 75% 선에서 입찰해야 낙찰 확률이 있고",
  },
  {
    title:"[경북의 끝-道界마을을 찾아서] <5>예천 소백산 저수령",
    body:"예천군 상리면 소재지에서 지방도 927호를 따라 충북 단양 방면으로 8㎞가량 가다 보면 경상북도와 충청북도의 접경인 음달마을에 이른다. 예천 최북단에 위치한 이 마을은 뒷산이 높아 해가 일찍 진다고 해 '음달'이라 불린다. 현재 이곳에는 예전부터 살던 토박이들이 대다수 떠나고, 귀농인 10가구 20여 명이 오순도순 삶의 터전을 일구고 있다. 예천군 상리면 용두리 음달마을에서 소백산 저수령(해발 850m)을 넘으면 충북 단양군 대강면 올산리가 나온다. 저수령을 경계로 경상도와 충청도",
  },
];

const search_result_3 = [
  {
    title:"적성면 새마을남여지도자협의회 감자심기 행사",
    body:" 단양군 적성면 새마을남여지도자협의회가 어려운 가정을 돕기 위한 '사랑의 감자 심기 행사'를 지난 31일 실시했다. 이번 감자 심기 행사는 적성면 현곡리 소재 휴경지(2천㎡)를 활용해 14개 리 새마을지도자, 부녀회장 등 30여 명이 참석했다. 적성면 새마을협의회는 오는 6월경 감자를 수확해 판매할 예정이며 판매 수익금은 80세.",
  },
  {
    title:"단양군 적성면 현곡리, ‘소각·산불 없는 녹색마을’ 현판식 ",
    body:"황주형 기자=단양군 적성면 현곡리 마을이 지난 15일 소각·산불 없는 녹색마을 현판식을 가졌다. 산림청은 지난 2014년부터 산림보호와 산불예방에 힘쓴 우수마을을 선정해 소각?산불 없는 녹색마을 현판을 전달하고 있다. 적성면 현곡리 마을은 선정에 참여한 전국 20,410개 마을의 치열한 경쟁을 뚫고 최종 300개 마을 중 하나로",
  },
  {
    title:"임실군 현곡리 악골 소하천 정비사업 추진",
    body:"임실군이 임실읍 현곡리 악골 소하천 정비사업에 대하여 상반기 용지매입을 시작으로 하반기 본격적인 사업에 착수한다. 악골 소하천 정비사업은 현곡마을 중앙을 흐르는 소하천을 정비하여 집중호우로부터 주택 및 농경지 침수피해 등의 재해를 예방하기 위하여 추진되는 사업이다. 2019년 행정안전부로부터 사업비를 확보하고 설계에 착수하여 하천 1.98㎞, 교량 9개소 설치 등 하천 정비에 2024년까지 145억원을 투입할 예정이다. 군은 지난 25일 현곡마을에서 설계과정 중 수렴된 주민 의견",
  },
  {
    title:"'단양 현곡리' 기획전",
    body:"서울시립대박물관이 1997년 10월 이후 약 70일 동안 조사한 충북 단양군 현곡리 유적 발굴 기획전을 14일 개막해 오는 12월18일까지 학교 박물관에서 '고려인의 영원한 삶, 단양 현곡리'라는 주제로 연다. 이 자리에는 고려시대 박쥐무늬 청동단추를 비롯한 출토유물이 선보인다. 2009.9.14 << 문화부 기사참조, 서울시립대박물관 제공 >>",
  },
  {
    title:"단양 매포 가평1리 '취약지역 생활여건 개조사업' 선정",
    body:"충북 단양군이 취약지역 생활여건 개조사업 공모에 선정돼 매포읍 가평1리 취약지역에 생활여건 개조사업을 추진한다. 29일 단양군에 따르면 국가균형발전위원회가 주관한 '2023년도 취약지역 생활여건 개조사업(농촌)' 공모에 매포읍 가평1리가 최종 선정됐다. 군은 2026년까지 4년간 15억2300만원(국비 9억7900만원, 지방비 3억6600만원, 자부담 1억7800만원) 규모의 사업비를 가평1리 마을에 투입할 예정이다.",
  },
  {
    title:"단양 현곡리 발굴 고려시대 유물 공개",
    body:"【단양=성진비즈】김찬욱 기자 = 서울시립대 박물관이 1997년 충북 단양군 적성면 중앙고속도로 건설현장에서 출토된 고려시대 유물 120여점을 공개했다. 14일 단양군에 따르면 이날 이 대학 박물관에서 개막된 유물전 '고려인의 영원한 삶, 단양 현곡리'는 12월18일까지 계속될 예정이다. 공개된 현곡리 유물은 중앙고속도로 건설공사 도중 발견된 고려시대 돌덧널무덤 27기, 널무덤 6기와 조선시대 널무덤 2기 등 무덤 35기에서 출토된 것이다.",
  },
  {
    title:"종교단체 신도9명 살해 암매장",
    body:"신도 실종 및 사기 사건 등으로 물의를 빚어온 한 종교단체가 배교(背敎) 등의 이유로 신도를 살해한 뒤 암매장한 것으로 드러났다. 검찰은 또 살해된 신도가 무려 9명에 이르고 사체를 전국에 암매장했다는 진술을 확보해 수사를 확대하고 있다. ◇수사=수원지검 강력부(부장검사 이지수)는 14일 부천 ㅇ종교단체 신도 김모(66)·정모(44)씨 등 2명에 대해 살인혐의로 구속영장을 청구했다. 검찰은 또 이 종교단체 교주 ㅈ씨(72)를 이날 오전 10시30분 김포공항에서 붙잡아 살인교사혐의로 긴급",
  },
  {
    title:"대구 ‘합동북’ 이호창 사장의 ‘헌책방 人生 30년’",
    body:"‘다 사라진 건 아닐까?’ 갑자기 헌책방이 궁금해졌다. 수소문을 해봤다. 대구의 경우 남문시장 근처에 4곳(코스모스·해바라기·월계·대도서점). 시청 근처에 5곳(규장각·대륙·평화·동양·제일서적)만 남아 있었다. 80년대 한창때는 도로 양옆에 즐비했던 대구역 지하도 헌책방은 흔적도 없이 사라졌고, 그 자리는 중국공예품 등 골동품 가게로 변해버렸다. 외곽지의 경우 북구 대현동 경대교 근처에 있는 합동북이 유일했다. ‘전멸’이라고 해도 과언이 아니었다. 헌책방이 ‘빙하기’에 들어선 것이",
  },
  {
    title:"[홍유상의 경매이야기]경매물건 급증.. 실수요자에겐 적극적인 입찰기회",
    body:"서울과 수도권의 전세 값 급등으로 인해 실수요자들의 경매투자에 대한 관심과 열기가 뜨겁다. 부동산 시장의 침체로 빚에 허덕이는 하우스 푸어들의 주택들이 법원경매에 부쳐지는 비율이 점점 높아지고 있는 것이다. 부동산 경기가 좋았을 때 대출을 많이 받아 집을 샀다가 매매 실종으로 늘어나는 이자를 감당하지 못하고 경매로 넘어오는 물건들이 많다. 전세대란은 내년 봄 이사철 때 까지도 이어질 전망이다. 중.소형 아파트나 빌라는 2회 유찰 후에 75% 선에서 입찰해야 낙찰 확률이 있고",
  },
  {
    title:"[경북의 끝-道界마을을 찾아서] <5>예천 소백산 저수령",
    body:"예천군 상리면 소재지에서 지방도 927호를 따라 충북 단양 방면으로 8㎞가량 가다 보면 경상북도와 충청북도의 접경인 음달마을에 이른다. 예천 최북단에 위치한 이 마을은 뒷산이 높아 해가 일찍 진다고 해 '음달'이라 불린다. 현재 이곳에는 예전부터 살던 토박이들이 대다수 떠나고, 귀농인 10가구 20여 명이 오순도순 삶의 터전을 일구고 있다. 예천군 상리면 용두리 음달마을에서 소백산 저수령(해발 850m)을 넘으면 충북 단양군 대강면 올산리가 나온다. 저수령을 경계로 경상도와 충청도",
  },
]

export {
  search_result_0,
  search_result_1,
  search_result_2,
  search_result_3
};