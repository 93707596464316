import '../styles/Footer.css';

function Footer () {
  return (
    <div className='Footer'>
      <div className='Br1'>전체서비스</div>
      <div className='Br1'>이용약관</div>
      <div className='Br1 fBold'>개인정보처리방침</div>
      <div className='Br1'>통합검색 고객센터</div>
      <div className='fBold'>© LEGOO Corp.</div>
    </div>
  )
}

export default Footer;