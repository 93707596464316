import '../styles/Header.css';

import { useEffect, useRef } from "react";

import { leagacyIcons as icons } from '../common/icons';

function Header ({ page, query, searchParam }) {
  const inputElem = useRef(null);

  const nextPage = (v) => {
    if(v) {
      window.location = `/search?ssc=${searchParam.get('ssc') || "all"}&query=${v}`
    }
  }

  const nextBtn = () => {
    inputFocusBlur(false);
    nextPage(inputElem.current.value);
  }

  const changeNowKey = (e) => {
    if(e.keyCode === 13) {
      inputFocusBlur(false);
      nextPage(e.target.value);
    }
  }

  const resetPage = () => {
    inputFocusBlur(false);
    window.location = '/';

    document.getElementById('search').value = '';

    window.scrollTo(0,0);
  }

  useEffect(() => {
    window.innerHeight - document.body.scrollHeight > 0 ?
    document.getElementById("Header").style.width = "100vw"
    :
    document.getElementById("Header").style.width = "calc(100vw - 17px)";
  },[page])

  const inputFocusBlur = (b) => {
    if (page === 0)
    {
      const box = document.getElementById('search_box');
      const icon = document.getElementById('search_icon');

      if(b){
        box.style.boxShadow = 'var(--box-shadow-3-hover)';
        icon.style.boxShadow = 'var(--box-shadow-3-hover)';
      }
      else
      {
        box.style.boxShadow = 'none';
        icon.style.boxShadow = 'none';
      }
    } else {
      const h = document.getElementById('Header');

      if (b) {
        h.classList.add(`onFocus`);
      } else {
        h.classList.remove(`onFocus`);
      }
    }
  }

  return (
    <div className={page === 0 ? "Header" : "Header header_hover_high"} id="Header">
      <div className='w1200 dFlex fCenter' id={page === 0 ? 'header160' : 'header60'}>
        <div onClick={resetPage} id='logo_box' style={{cursor: 'pointer', userSelect: 'none'}}>
          <div id='logo'>
            <img src={icons.logo} alt='logo' />
          </div>
          {
            page === 0 ?
            <div id='logo_text'>LEGOO</div>
            :
            <div id='logo_bar'></div>
          }
        </div>

        <div id='search_box' className={page === 0 ? 'search_box_home' : 'search_box_none'}>
          <input
            ref={inputElem}
            onFocus={() => inputFocusBlur(true)}
            onBlur={() => inputFocusBlur(false)}
            id='search'
            type='text'
            placeholder=''
            autoComplete='off'
            onKeyDown={changeNowKey}
            spellCheck='false'
            defaultValue={query || ""}
          />

          <img className='search_icons' src={icons.search_keyboard} alt='serach_down' />
          <img className='search_icons' src={icons.search_down} alt='serach_down' />
        </div>

        {
          page === 0 ?
          (
            <div id='search_icon' onClick={nextBtn}>
              <img src={icons.search} alt='serach_down' />
            </div>
          )
          :
          (
            <div id='search_icon' className='search_icon_no' onClick={nextBtn}>
              <img src={icons.search2} alt='serach_down' />
            </div>
          )
        }

        <div className='blank350'></div>
      </div>
    </div>
  )
}

export default Header;