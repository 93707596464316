import "./ResultLeft.css";

import { v4 as uuidv4 } from "uuid";
import ResultLeftList from "./ResultLeftList";

function ResultLeft({ title, titleBtn, content, strong, setting, end, middleBar }) {
  const isViewCheckArr = [`place`];
  const isDualCheckArr = [`place`];

  const isViewValid = isViewCheckArr.includes(setting);
  const isDualValid = isDualCheckArr.includes(setting);

  return (
    <div className="ResultLeft">
      <div className="ResultLeftBox">
        <div className={`rl_common ${setting}`}>
          <div className="n_left_header">
            <div className="n_left_header_title">{title}</div>
            <div className="fBlank"></div>
            <div className="title_btn">
              {titleBtn ? (
                <>
                  {Array.isArray(titleBtn) ? (
                    <>
                      {titleBtn.map((e, i) => {
                        return <div key={`title_btn_key_${i}`}>{e}</div>;
                      })}
                    </>
                  ) : (
                    <div>{titleBtn}</div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          {
            middleBar ?
              <div className="result_left_middleBar">
                {
                  middleBar[0].map((e, i) => {
                    return (
                      <div className={i === middleBar[1] ? "middleBar_btn selected" : "middleBar_btn"} key={`middleBar_btn_key_${uuidv4()}`}>
                        {e}
                      </div>
                    )
                  })
                }
              </div>
              :
              <></>
          }

          <div className={`result_left_header_setting ${setting} is_dual_${isDualValid}`}>

            {
              isDualValid ?
              <>
                {
                  content && content.length === 2 ?
                  <>
                    <div className="result_left_dual_l">
                      {
                        content[0].map((e, i) => {
                          const key = uuidv4();

                          return (
                            <ResultLeftList
                              key={key}
                              name={e.name}
                              sub={e.sub}
                              title={e.title}
                              body={e.body}
                              img={e.img}
                              place={e.place}
                              id={key}
                              bb={i !== content[0].length - 1}
                              keypoint={strong ? strong : null}
                              isView={isViewValid}
                            />
                          )
                        })
                      }
                    </div> 
                    
                    <div className="result_left_dual_r">
                      {
                        content[1].map((e, i) => {
                          const key = uuidv4();

                          return (
                            <ResultLeftList
                              key={key}
                              name={e.name}
                              sub={e.sub}
                              title={e.title}
                              body={e.body}
                              img={e.img}
                              place={e.place}
                              id={key}
                              bb={i !== content[1].length - 1}
                              keypoint={strong ? strong : null}
                              isView={isViewValid}
                            />
                          )
                        })
                      }
                    </div> 
                  </>
                  :
                  <></>
                }
              </>
              :
              <>
                {
                  content && Array.isArray(content) ?
                  <>
                    {
                      content.map((e, i) => {
                        const key = uuidv4();

                        return (
                          <ResultLeftList
                            key={key}
                            name={e.name}
                            sub={e.sub}
                            title={e.title}
                            body={e.body}
                            img={e.img}
                            id={key}
                            bb={i !== content.length - 1}
                            keypoint={strong ? strong : null}
                            isView={isViewValid}
                          />
                        )
                      })
                    }
                  </>
                  :
                  <></>
                }
              </>
            }
            
          </div>

          {
            end ?
            <div className="news_bottom">
              {end}
            </div>
            :
            <></>
          }
        </div>
      </div>
    </div>
  );
}

export default ResultLeft;
