import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { news_content } from '../content/news_content';
import NewsProfile from './News/NewsProfile';
import { strongStr } from '../common/commonFunc';

export default function News({ icons, newsContent, newsViews, type, strong }) {
  const setProfile = false;

  const modalOn = () => {
    document.getElementById('modal').style.display = "flex";
  }

  const modalClose = () => {
    document.getElementById('modal').style.display = "none";
  }

  function NewsList({ news, title, body, img, id, bb, isView, keypoint }) {
    const idTitle = "news_title_id_" + id;
    const idBody = "news_body_id_" + id;

    useEffect(() => {
      if (keypoint) {
        let newTitle = title;
        let newText = body;

        keypoint.split(/ /).forEach((e) => {
          newTitle = newTitle.replaceAll(e, strongStr(e));
          newText = newText.replaceAll(e, strongStr(e));
        });

        document.getElementById(idTitle).innerHTML = newTitle;
        if (body !== undefined && body !== null) {
          document.getElementById(idBody).innerHTML = newText;
        }
      } else {
        document.getElementById(idTitle).innerHTML = title;
        document.getElementById(idBody).innerHTML = body;
      }
    });

    return (
      <div className={bb ? 'news_list nr_bb' : 'news_list'}>
        {
          isView ?
          <>
          </>
          :
          <div className='news_header'>
            <div className='news_img'>
              <img src={news.icon} alt='news_header' />
            </div>

            <div className='news_name'>{news.name}</div>
            <div className="news_header_dot">ㆍ</div>
            <div className="news_header_pick">LEGOO뉴스</div>

            <div className='news_icon'>
              <icons.Ellipse />
            </div>
          </div>
        }

        <div className='news_content'>
          <div className='news_body_left'>
            <div id={idTitle} className='news_title textUnder'>
            </div>

            <div id={idBody} className='news_body' style={{ cursor: "pointer" }}>
            </div>
          </div>

          <div className='news_body_right'>
            {
              img ?
              <div className='news_right_img'>
                <img onClick={modalOn} src={img} alt='news_img' />
              </div>
              :
              <div className='news_right_img' style={{ backgroundColor: "#fff" }}>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

  function NewsMore () {
    return (
      <div className='news_list nr_bb news_more'>
        <div>관련문서 더보기</div>
        <div>
          <icons.Right />
        </div>
      </div>
    )
  }

  function Modal ({ title, body }) {
    useEffect(() => {
      document.getElementById('modal_title').innerHTML = title;
      document.getElementById('modal_body').innerHTML = body;
    });

    return (
      <div id='modal' className='Modal'>
        <div id='modal_img'>
          <img src={"/images/resultPage01/06.png"} alt='modal_img' />
          <div id='modal_close' onClick={modalClose}>X</div>
        </div>

        <div id='modal_content'>
          <div className='news_header'>
            <div className='news_img'>
              <img src={'/images/newslogo_circle/circle_8.png'} alt='news_header' />
            </div>

            <div className='news_name'>{"KBM"}</div>
            <div className="news_header_dot">ㆍ</div>
            <div className="news_header_pick">LEGOO뉴스</div>

            <div className='news_icon'>
              <icons.Ellipse />
            </div>
          </div>

          <div id='modal_title'>
            
          </div>

          <div id='modal_body'>
            
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='NewsBox'>
      <Modal
        title={news_content[5].title}
        body={news_content[5].body}
      />

      <div>
        {
          setProfile ?
          <div className='News'>
            <NewsProfile
              name={"차은새"}
              job={"배우"}
              icons={icons}
              pic={`/images/resultPage01/03.png`}
            />
          </div>
          :
          <></>
        }

        <div className='News'>
          <div className='n_left mr20'>

            {
              type === 1 ?
              <div className='n_left_header' style={{borderBottom: "var(--border-legoo)", paddingBottom: "15px"}}>
                <div className='n_left_header_title' style={{cursor: "pointer", color: "#555", fontSize: "16px", fontWeight: "400"}}>옵션 ↓</div>
                <div className='fBlank'></div>
                <div className='fGray mr10 curPointer hoverUnder'>ㆍ관련도순</div>
                <div className='fBold curPointer hoverUnder'>ㆍ최신순</div>
              </div>
              :
              <div className='n_left_header'>
                <div className='n_left_header_title'>뉴스</div>
                <div className='fBlank'></div>
                <div className='fBold mr10 curPointer hoverUnder'>ㆍ관련도순</div>
                <div className='fGray curPointer hoverUnder'>ㆍ최신순</div>
              </div>
            }

            {
              newsContent ?
              newsContent.map((e,i) => {
                const key = uuidv4();

                return e.more ?
                (
                  <NewsMore key={key} />
                )
                :
                (
                  <NewsList
                    key={key}
                    news={e.news}
                    title={e.title}
                    body={e.body}
                    img={e.img}
                    id={key}
                    bb={i !== newsContent.length - 1}
                    keypoint={strong ? strong : null}
                  />
                )
              })
              :
              <></>
            }

            <div className='news_bottom'>
              뉴스 더보기 →
            </div>
          </div>        
        </div>

        {
          newsViews ?
          <div className='News' style={{marginBottom: "30px"}}>
            <div className='n_left mr20'>
              <div className='n_left_header'>
                <div className='n_left_header_title'>페이지</div>
                <div className='fBlank'></div>
                <div className='fBold mr10 curPointer hoverUnder'>ㆍ관련도순</div>
                <div className='fGray curPointer hoverUnder'>ㆍ최신순</div>
              </div>

              {
                newsViews.map((e,i) => {
                  const key = uuidv4();

                  return (
                    <NewsList
                      isView={true}
                      key={key}
                      title={e.title}
                      body={e.body}
                      img={e.img}
                      id={key}
                      bb={i !== newsContent.length - 1}
                      keypoint={strong ? strong : null}
                    />
                  )
                })
              }
              <div className='news_bottom'>
                페이지 더보기 →
              </div>
            </div>
          </div>
          :
          <></>
        }
      </div>
    </div>
  );
}