import './LoginModal.css';

import { userInfo } from '../../WebHard/webhard_content';

const loginModalOn = async () => {
  const modal = document.getElementById('login_modal');
  const modal_box = document.getElementById('login_modal_box');

  modal.style.display = 'flex';

  setTimeout(() => {
    modal_box.classList.add('show');
  }, 20);
}

function LoginModal() {
  const loginModalOff = async () => {
    const modal = document.getElementById('login_modal');
    const modal_box = document.getElementById('login_modal_box');

    modal_box.classList.remove('show');

    setTimeout(() => {
      modal.style.display = 'none';
    }, 500);
  }

  const pwKeyDown = (e) => {
    if(e.keyCode === 13) {
      tryLogin();
    }
  }

  const loginPWCheck = () => {
    const checkBox = document.getElementById('login_pw_check');
    checkBox.checked = !checkBox.checked;
    loginPWCheckShow();
  }

  const loginPWCheckShow = () => {
    const checkBox = document.getElementById('login_pw_check');
    const pw = document.getElementById('loginInput_pw');

    if(checkBox.checked) {
      pw.type = 'text';
    } else {
      pw.type = 'password';
    }
  }

  const loginFail = () => {
    const valid = document.getElementById('loginValid');

    document.getElementById('loginInput_pw').value = "";

    valid.classList.add('no', 'vibe');

    setTimeout(() => {
      valid.classList.remove('vibe');
    }, 300);
  }

  const tryLogin = () => {
    const id = document.getElementById('loginInput_id').value;
    const pw = document.getElementById('loginInput_pw').value;

    const loading = document.getElementById('loginTry');

    loading.style.opacity = 1;
    document.getElementById('loginValid').classList.remove('no');

    setTimeout(() => {
      loading.style.opacity = 0;

      if(userInfo[id]) {
        if(userInfo[id] === pw) {
          loginModalOff();
  
          setTimeout(() => {
            window.location = `/drive?user=${id}`;
            document.getElementById('loginInput_id').value = "";
            document.getElementById('loginInput_pw').value = "";
            document.getElementById('login_pw_check').checked = false;
            document.getElementById('loginValid').classList.remove('no');
          }, 500);
          return;
        }
      }
  
      loginFail();
    }, 200);
  }

  return (
    <div className='LoginModal' id='login_modal'>
      <div className='lbox' id='login_modal_box'>
        <div id="logo_box">
          <div id="logo">
            <img src="/images/logo.png" alt="logo" />
          </div>
          <div id="logo_text">LEGOO</div>
        </div>

        <div style={{height: "60px"}}></div>

        <div className='lrow'>
          <input 
            id='loginInput_id'
            className='loginInput id'
            placeholder='ID'
            type='text' autoComplete='off' spellCheck='false'
            onKeyDown={pwKeyDown}
          />
        </div>
        <div className='lrow'>
          <input
            id='loginInput_pw'
            className='loginInput pw'
            placeholder='비밀번호'
            type='password' autoComplete='off' spellCheck='false'
            onKeyDown={pwKeyDown}
          />
        </div>

        <div className='lrow checkbox'>
          <input onChange={loginPWCheckShow} id='login_pw_check' type='checkbox' />
          <div onClick={loginPWCheck} style={{flex: "1 0 auto"}}>비밀번호 표시</div>
        </div>

        <div onClick={tryLogin} className='lrow' id='login_btn'>
          로그인
        </div>

        <div className='lrow' id='loginValid'>
          <div id='loginTry'>
            <div className='loader1'></div>
          </div>
          <div>계정 또는 패스워드가 맞지 않습니다.</div>
          <div>다시 시도하십시오.</div>
        </div>

        <div className='lrow q'>
          <div>계정이 없나요?</div>
        </div>
        <div className='lrow q'>
          <div>로그인에 문제가 있나요?</div>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;

export {
  loginModalOn
}