import './WebHardHome.css';

import { useRef } from 'react';
import { icons } from '../../../common/IconsFunc';

function WebHardHome({ params }) {
  const isFullScreen = useRef(false);

  const chroma_modal_on = () => {
    if(!isFullScreen.current) {
      document.getElementById('modal_chroma_full').style.display = 'block';
      document.documentElement.requestFullscreen();
      isFullScreen.current = true;
    }
  }

  const chroma_modal_off = () => {
    if(isFullScreen.current) {
      document.exitFullscreen();
      document.getElementById('modal_chroma_full').style.display = 'none';
      isFullScreen.current = false;
    }
  }

  window.addEventListener('keydown', (e) => {
    if(e.key === "q") {
      chroma_modal_off();
    }
  })

  return (
    <div className='WebHardHome'>
      <div className='CommonSidebar'>
        <div className='list selected'>
          <span>▼</span>
          <div className='t'>home</div>
        </div>

        <div className='line'></div>

        <div className='list'>
          <span>▶</span>
          <div className='t'>모든 파일</div>
        </div>
        <div className='list'>
          <span>▶</span>
          <div className='t'>최근</div>
        </div>
        <div className='list'>
          <span>▶</span>
          <div className='t'>컴퓨터</div>
        </div>

        <div className='line'></div>

        <div className='list'>
          <span>▶</span>
          <div className='t'>공유 문서함</div>
        </div>
        <div className='list'>
          <span>▶</span>
          <div className='t'>중요 문서함</div>
        </div>
        <div className='list'>
          <span>▶</span>
          <div className='t'>암호 문서함</div>
        </div>
      </div>

      <div className='WebHardMain'>
        <div className='header'>
          <div className='btns'>
            <div>
              <icons.Left />
            </div>
            <div>
              <icons.Right />
            </div>
          </div>

          <div className='btns'>
            <div><icons.Refresh /></div>
          </div>

          <div className='fBlank directory'>
            {/*
              // todo serachParam => query folder directory
            */}
            {`home ↓`}
          </div>
        </div>

        <div className='header'>
          <div className='btns'>
            <div>폴더 생성 +</div>
          </div>

          <div className='btns'>
            <div>업로드 ↓</div>
          </div>

          <div className='btns'>
            <div>작업 ↓</div>
          </div>
          <div className='btns'>
            <div>도구 ↓</div>
          </div>
          <div className='btns'>
            <div>설정 ↓</div>
          </div>

          <div className='fBlank'></div>

          <div className='btns'>
            <div><icons.Menu /></div>
          </div>

          <div className='btns end'>
            <div><icons.Table /></div>
          </div>
        </div>

        <div className='line'></div>

        <div className='row'>
          <div onClick={chroma_modal_on} className='square'>
            <div className='content'>
              <div className='video'>
                <icons.Play />
              </div>
            </div>
            <div className='text'>
              <div className='title'>2009_12_24.mp4</div>
              <div className='volume'></div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='square'>
            <div className='header'>

            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default WebHardHome;