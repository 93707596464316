import drive_intro from '../../../images/drive/drive_intro.png';

import './WebHardIntro.css';

function WebHardIntro({ loginModalOn }) {
  return (
    <div className='WebHardIntro intro'>
      <div className='box'>
        <div className='text'>
          <div className='t1'>
            내 콘텐츠에 빠르고 안전하게 액세스
          </div>
          
          <div className='t2'>
            사진과 문서 자료를 한 곳에서 안전하게 보관하고 작업할 수 있습니다.
          </div>
          <div className='t2'>
            강력한 사진 정리와 감상, 문서 조회와 편집, 공유 기능을 이용해보세요.
          </div>
          <div className='t2'>
            휴대기기, 태블릿, 데스크탑에서 파일과 폴더를 저장, 공유하고 공동작업을 진행해보세요.
          </div>

          <div className='btns'>
            <div onClick={loginModalOn || null} className='common_header_btn_square'>
              DRIVE 이동
            </div>
            <div className='common_header_btn_square boxHigh'>
              업무용 DRIVE 사용해 보기
            </div>
          </div>

          <div className='btns'>
            <div onClick={loginModalOn || null} className='common_header_btn_square noBorder colorHigh'>
              로그인
            </div>
            <div className='common_header_btn_square noBorder'>
              계정이 없나요?
            </div>
          </div>
        </div>
        <div className='img'>
          <img src={drive_intro} alt='drive_intro' />
        </div>
      </div>

      <div className='foot'>
        <div>LEGOO DRIVE 기능 알아보기</div>
        <div className='btn'>↓</div>
      </div>
    </div>
  );
}

export default WebHardIntro;