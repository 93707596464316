import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

function ResultImage({ result_images }) {
  const [chunk, setChunk] = useState([0,0]);

  useEffect(() => {
    const length = result_images.length;
    setChunk([ Math.floor(length / 3), Math.floor(length / 3 * 2) ]);
  },[result_images.length]);

  return (
    <div className='ResultImages'>
      {
        chunk[0] ?
        <>
          <div className='result_image_0'>
          {
            result_images.slice(0, chunk[0]).map((e) => {
              return (
                <div key={uuidv4()} className='result_image'>
                  <div className='result_image_box'>
                    <img src={e.img} alt='rib' />
                  </div>
                  <div onClick={e.func || null} className='result_image_text'>{e.body}</div>
                </div>
              )
            })
          }
          </div>
          <div className='result_image_0'>
          {
            result_images.slice(chunk[0], chunk[1]).map((e) => {
              return (
                <div key={uuidv4()} className='result_image'>
                  <div className='result_image_box'>
                    <img src={e.img} alt='rib' />
                  </div>
                  <div onClick={e.func || null} className='result_image_text'>{e.body}</div>
                </div>
              )
            })
          }
          </div>
          <div className='result_image_end'>
          {
            result_images.slice(chunk[1]).map((e) => {
              return (
                <div key={uuidv4()} className='result_image'>
                  <div className='result_image_box'>
                    <img src={e.img} alt='rib' />
                  </div>
                  <div onClick={e.func || null} className='result_image_text'>{e.body}</div>
                </div>
              )
            })
          }
          </div>
        </>
        :
        <></>
      }
    </div>
  );
}

export default ResultImage;