import { useEffect } from "react";

import { icons } from "../../common/IconsFunc";
import { strongStr } from "../../common/commonFunc";

function ResultLeftList({ name, sub, title, body, img, id, bb, isView, keypoint, place }) {
  const idTitle = "news_title_id_" + id;
  const idBody = "news_body_id_" + id;

  useEffect(() => {
    if (keypoint) {
      let newTitle = title;
      let newText = body;

      keypoint.split(/ /).forEach((e) => {
        newTitle = newTitle.replaceAll(e, strongStr(e));
        newText = newText.replaceAll(e, strongStr(e));
      });

      document.getElementById(idTitle).innerHTML = newTitle;
      if (body !== undefined && body !== null) {
        document.getElementById(idBody).innerHTML = newText;
      }
    } else {
      document.getElementById(idTitle).innerHTML = title;
      document.getElementById(idBody).innerHTML = body;
    }
  });

  return (
    <div className={bb ? 'news_list nr_bb' : 'news_list'}>
      {
        isView ?
        <>
        </>
        :
        <div className='news_header'>
          <div className={sub ? 'news_name' : `news_name fBlank`}>{name}</div>
          {
            sub ?
            <>
              <div className="news_header_dot">ㆍ</div>
              <div className="news_header_pick">{sub}</div>
            </>
            :
            <></>
          }

          <div className='news_icon'>
            <icons.Ellipse />
          </div>
        </div>
      }

      <div className='news_content'>
        <div className='news_body_left'>
          <div id={idTitle} className='news_title textUnder'>
          </div>

          <div id={idBody} className='news_body' style={{ cursor: "pointer" }}>
          </div>

          {
            place ?
            <>
              {
                place.map((e, i) => {
                  const v = i === place.length - 1;
                  const isFill = Boolean(e || v);
                  return (
                    <div key={`${id}_place_${i}`} className={`place_line place_${i} isFill_${isFill}`}>
                      {
                        v ?
                        <div className="place_btns">
                          <div className="place_btn">
                            <icons.Clock />
                            <span>예약</span>  
                          </div>
                          <div className="place_btn">
                            <icons.CommentDot />
                            <span>톡톡</span>  
                          </div>
                        </div>
                        :
                        <></>
                      }

                      {
                        v ? <span className="i"><icons.ClipBoard /></span> : <></>
                      }

                      {e}
                    </div>
                  )
                })
              }
            </>
            :
            <></>
          }
        </div>

        {
          img ?
          <div className='news_body_right'>
            <div className='news_right_img'>
              <img src={img} alt='news_img' />
            </div>
          </div>
          :
          <></>
        }
      </div>
    </div>
  );
}

export default ResultLeftList;