import './WebHardHeader.css';
import { icons } from '../../../common/IconsFunc';

// **
// * this is common header component
// * Read the common style
// **

function WebHardHeader({ isLogin, setSearchParams }) {
  const goHome = () => {
    window.location = '/';
  }

  const logIn = () => {

  }

  const logOut = () => {
    window.location = `/drive`;
  }

  return (
    <div className='CommonHeaderBox'>
      <div className='CommonHeader WebHardHeader'>
        <div className='common_header_logo'>
          <img onClick={goHome} src='/images/logo.png' alt='logo' />
          <span>DRIVE</span>
        </div>       

        {
          isLogin ?
          <div className='common_search'>
            <div className='common_search_icon'>
              <icons.Header1 />
            </div>
            <input
              className='common_search_input'
              placeholder='드라이브에서 검색'
              type='text' autoComplete='off' spellCheck='false' />
            <div className='common_search_icon'>
              <icons.Slider />
            </div>
          </div>
          :
          <div style={{marginLeft: "30px", display: "flex", alignItems: "center"}}>
            <div className='common_header_btn selected'>개요</div>
            <div className='common_header_btn'>특징</div>
            <div className='common_header_btn'>고객</div>
            <div className='common_header_btn'>가격</div>
            <div className='common_header_btn'>다운로드</div>
          </div>
        }

        <div className='fBlank'></div>

        {
          isLogin ?
          <>
            <div className='common_header_icon plus'>
              <icons.GearB />
            </div>
            <div className='common_header_icon'>
              <icons.Compass />
            </div>
            <div className='common_header_icon'>
              <icons.Bell />
            </div>
            {/* <div className='common_header_icon'>
              <icons.Menu />
            </div> */}
          </>
          :
          <>
            <div className='common_header_btn_square noBorder'>
              도구 더보기 ↓
            </div>
            <div className='common_header_btn_square noBorder colorHigh'>
              무료로 가입하기
            </div>
            <div className='common_header_btn_square'>
              DRIVE 이동
            </div>
            <div className='common_header_btn_square boxHigh'>
              업무용 DRIVE 사용해 보기
            </div>
          </>
        }

        {
          isLogin ?
          <div onClick={logOut} className='common_header_login'>
            MY ↓
          </div>
          :
          <div onClick={logIn} className='common_header_login'>
            로그인
          </div>
        }
      </div>

      <div className='CommonHeaderBlank'></div>
    </div>
  );
}

export default WebHardHeader;