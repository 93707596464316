import { useEffect, useState } from 'react';
import './App.css';

import './styles/Common.css';
import './styles/CommonHeader.css';
import './styles/CommonSidebar.css';

import Header from './components/Header';
import MiddleBar from './components/MiddleBar';
import Footer from './components/Footer';

import { Outlet, useSearchParams } from 'react-router-dom';

function App() {
  const [page, setPage] = useState(0);
  const [isEng, setEng] = useState(false);
  const [searchParam, setSearchParams] = useSearchParams();

  useEffect(() => {
    if(window.location.pathname.includes("/search")) {
      setPage(1);
    }

    if (searchParam.get('lng')) {
      setEng(true);
    } else {
      setEng(false);
    }
  },[page, searchParam])

  return (
    <div className="App">
      <Header
        page={page}
        query={searchParam.get('query')}
        searchParam={searchParam}
      />

      <MiddleBar
        page={page}
        searchParam={searchParam}
        setSearchParams={setSearchParams}
        isEng={isEng}
      />

      <Outlet
        context={{
          query: searchParam.get('query'),
          ssc: searchParam.get('ssc'),
          setSearchParams: setSearchParams,
          isEng: isEng,
        }}
      />

      <Footer />
    </div>
  );
}

export default App;
