import search from '../icons/search.svg';
import search2 from '../icons/search2.svg';
import search_down from '../icons/search_down.svg';
import search_keyboard from '../icons/search_keyboard.svg';
import search_up from '../icons/search_up.svg';
import smile from '../icons/smile.svg';
import news from '../icons/news.svg';
import news_gear from '../icons/news_gear.svg';
import news_list from '../icons/news_list.svg';
import news_table from '../icons/news_table.svg';
import logo from '../images/LE_logo.png';

const leagacyIcons = {
  logo: logo,
  search: search,
  search2: search2,
  search_down: search_down,
  search_keyboard: search_keyboard,
  search_up: search_up,
  smile: smile,
  news: news,
  news_gear: news_gear,
  news_list: news_list,
  news_table: news_table,
};

export {
  leagacyIcons
};