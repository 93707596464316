import '../styles/MiddleBar.css';

import { icons as iconsF } from '../common/IconsFunc';

import { leagacyIcons as icons } from '../common/icons';
import { useEffect } from 'react';

function MiddleBar ({ page, searchParam, setSearchParams, isEng }) {
  const highChange = (e) => {
    let target = e.target;
    const nodeName = target.nodeName;
    let parent = target.parentElement;

    if(nodeName === "path") {
      target = parent.parentElement;
      parent = parent.parentElement.parentElement;
    } else if (nodeName === "svg") {
      target = parent;
      parent = parent.parentElement;
    }
    
    parent.childNodes.forEach(e => e.classList.remove("newHigh"));
    target.classList.add("newHigh");
  }

  const changeSSC = (query) => {
    setSearchParams(`ssc=${query}&query=${searchParam.get('query')}`);
  }

  const highChangeManual = (t) => {
    if(t) {
      if(t.classList.contains('newMiddle')) {
        t.parentElement.childNodes.forEach(e => e.classList.remove("newHigh"));
        t.classList.add("newHigh");
      }
    }
  }

  const goDrive = () => {
    window.location = '/drive';
  }

  useEffect(() => {
    const eArr = document.getElementsByClassName('newMiddle');

    switch (searchParam.get('ssc')) {
      case "all":
        highChangeManual(eArr[0]);
        break;
      case "image":
        highChangeManual(eArr[4]);
        break;
      case "news":
        highChangeManual(eArr[9]);
        break;
      default:
        highChangeManual(eArr[0]);
        break;
    }
  })

  return (
    page === 0 ?
    (
      <div className='MiddleBar'>
        {
          isEng ?
            <div className='w1200 dFlex fCenter' id='MiddleBar'>
              <div className='fBold fHigh mr20' onClick={() => window.location = '/mail'}>Mail</div>
              <div className='fBold fHigh mr20'>Cafe</div>
              <div className='fBold fHigh mr20'>Blog</div>
              <div className='fBold fHigh mr20'>Know</div>
              <div className='fBold fHigh mr20'>Shop</div>
              <div className='fBold fHigh mr20'>Pay</div>
              <div className='fBold fHigh mr20'>TV</div>
              {/* <div className='fBold mr20'>Dict.</div> */}
              <div className='fBold mr20'>News</div>
              <div className='fBold mr20'>Sport</div>
              <div className='fBold mr20'>Stock</div>
              <div className='fBold mr20'>Estate</div>
              <div className='fBold mr20'>Map</div>
              <div className='fBold mr20'>Book</div>
              <div className='fBold mr20'>Webtoon</div>
              <div className='fBold mr20'>Game</div>
              <div onClick={goDrive} className='fBold mr20'>Drive</div>
    
              <div className='blankFlex'></div>
    
              <div id='middleBar_weather'>
                <div className='mW_f'></div>
                <div className='mW_i'>
                  <img src={icons.smile} alt='smile' />
                </div>
                <div className='mW_e'>Dust</div>
    
                <div className='mW_b'>|</div>
    
                <div className='mW_f'></div>
                <div className='mW_i'>
                  <img src={icons.smile} alt='smile' />
                </div>
                <div className='mW_e'>Fine Dust</div>
    
                <div className='mW_m'>weather</div>
              </div>
            </div>
            :
            <div className='w1200 dFlex fCenter' id='MiddleBar'>
              <div className='fBold fHigh mr20' onClick={() => window.location = '/mail'}>메일</div>
              <div className='fBold fHigh mr20'>카페</div>
              <div className='fBold fHigh mr20'>블로그</div>
              <div className='fBold fHigh mr20'>지식</div>
              <div className='fBold fHigh mr20'>쇼핑</div>
              <div className='fBold fHigh mr20'>페이</div>
              <div className='fBold fHigh mr20'>TV</div>
              <div className='fBold mr20'>사전</div>
              <div className='fBold mr20'>뉴스</div>
              <div className='fBold mr20'>스포츠</div>
              <div className='fBold mr20'>증권</div>
              <div className='fBold mr20'>부동산</div>
              <div className='fBold mr20'>지도</div>
              <div className='fBold mr20'>도서</div>
              <div className='fBold mr20'>웹툰</div>
              <div className='fBold mr20'>게임</div>
              <div onClick={goDrive} className='fBold mr20'>드라이브</div>

              <div className='blankFlex'></div>

              <div id='middleBar_weather'>
                <div className='mW_f'>미세</div>
                <div className='mW_i'>
                  <img src={icons.smile} alt='smile' />
                </div>
                <div className='mW_e'>좋음</div>

                <div className='mW_b'>|</div>

                <div className='mW_f'>초미세</div>
                <div className='mW_i'>
                  <img src={icons.smile} alt='smile' />
                </div>
                <div className='mW_e'>좋음</div>

                <div className='mW_m'>더보기</div>
              </div>
            </div>
        }
      </div>
    )
    :
    (
      <div className='MiddleBar topPadding'>
        <div className='dFlex' id='MiddleBar'>
          <div onClick={() => changeSSC('all')} className='mr20 newMiddle newHigh'>
            <iconsF.Header1 />
            통합검색
          </div>
          <div className='mr20 newMiddle'>
            <iconsF.Header2 />
            사이트
          </div>
          <div className='mr20 newMiddle'>
            <iconsF.Header3 />
            카페
          </div>
          <div className='mr20 newMiddle'>
            <iconsF.Header4 />
            블로그
          </div>
          <div onClick={() => changeSSC('image')}  className='mr20 newMiddle'>
            <iconsF.Header5 />
            이미지
          </div>
          <div className='mr20 newMiddle'>
            <iconsF.Header6 />
            동영상
          </div>
          <div className='mr20 newMiddle'>
            <iconsF.Header7 />
            지식
          </div>
          <div className='mr20 newMiddle'>
            <iconsF.Header8 />
            인플루언서
          </div>
          <div className='mr20 newMiddle'>
            <iconsF.Header9 />
            쇼핑
          </div>
          <div onClick={() => changeSSC('news')} className='mr20 newMiddle'>
            <iconsF.Header0 />
            뉴스
          </div>
          <div className='mr20 newMiddle'>
            <iconsF.Header10 />
            어학사전
          </div>
          <div className='mr20 newMiddle'>
            <iconsF.Header11 />
            지도
          </div>
          <div className='mr20 newMiddle'>
            <iconsF.Header12 />
            도서
          </div>
          <div onClick={highChange} className='mr20 newMiddle'>
            <iconsF.Header13 />
            학술정보
          </div>
          <div className='blankFlex page_1_blank'></div>
        </div>
        <div id='middlebar_right_btn'>
          <div className='btn'>{"→"}</div>
        </div>
      </div>
    )
  )
}

export default MiddleBar;