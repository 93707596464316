const review_input = "화이트 가든";

const review_result = [
  {
    n: "이정규",
    r: 37,
    p: 57,
    t: "시작하는 연인을 위한 최고의 장소",
  },
  {
    n: "sohe****",
    r: 303,
    p: 83,
    t: "고백할 장소를 고민 중이라면 적극 추천.",
  },
  {
    n: "한식lover",
    r: 1103,
    p: 976,
    t: "첫 데이트 장소로 골랐는데 만족합니다. ",
  },
  {
    n: "mya",
    r: 30,
    p: 45,
    t: "로맨틱함을 만끽하고 싶다면 여기로! ",
  },
  {
    n: "맛그리니",
    r: 145,
    p: 484,
    t: "우리 커플이 가장 좋아하는 레스토랑. ",
  },
  {
    n: "넝담",
    r: 149,
    p: 273,
    t: "특별한 날에는 이런 특별한 곳에서. ",
  },
];

const review_keyword = [
  {
    n: "음식이 맛있어요",
    i: 762
  },
  {
    n: "특별한 메뉴가 있어요",
    i: 533
  },
  {
    n: "양이 많아요",
    i: 488
  },
  {
    n: "친절해요",
    i: 412
  },
];

const review_result_menus = [
  {
    n: "푸짐한 정통 미국식 브런치",
    v: "15,500",
    t: "정성스럽게 구운 팬케익과 프렌치토스트에 각종 사이드 디쉬, 그리고 샐러드를 곁들인 푸짐한 정통 미국식 브런치",
    i: 1,
  },
  {
    n: "얼그레이 크림 프렌치 토스",
    v: "15,900",
    t: "버터의풍미가가득한브리오슈식빵에 직접만든얼그레이크림, 제철과일을 함께즐기는프렌치토스트",
    i: 2,
  },
  {
    n: "비프 콜드 누들 샐러",
    v: "17,900",
    t: "오리엔탈 드레싱에 고소한 우삼겹을 카펠리니면과 곁들여 먹는 누들 샐러드",
    i: 3,
  },
  {
    n: "채끝등심 스테이크 (250g)",
    v: "39,500",
    t: "부드럽고 풍부한 육즙의 최고급 프라임 채끝등심 (250g) 과 구운야채 37.5메인스테이크",
    i: 4,
  },
  {
    n: "수제 통새우 버거",
    v: "15,900",
    t: "매장에서 직접 갈아만든 100% 새우살 수제패티와 각종 수제소스, 유기농 번으로 만드는 37.5 시그니처 버거",
    i: 5,
  },
  {
    n: "리코타 치즈 샐러드",
    v: "15,500",
    t: "매장에서 직접 끊여서 만든 수제 리코타치즈와 제철과일 그리고 호밀빵으로 즐기는 시그니처 샐러드",
    i: 6,
  },
];

export {
  review_input,
  review_keyword,
  review_result,
  review_result_menus,
};