function NewsSide({ setPage }) {
  const changeHigh = (e) => {
    let target = e.target;
    let parent = target.parentElement;

    parent.childNodes.forEach(e => e.classList.remove("news_side_btn_high"));
    target.classList.add("news_side_btn_high");

    window.scrollTo(0,0);
    setPage(61);
    document.getElementById('search').value = "";
  }

  return (
    <div className='NewsSide'>
      <div className='news_side_title'>뉴스</div>
      <div onClick={changeHigh} className='news_side_btn'>정치</div>
      <div onClick={changeHigh} className='news_side_btn'>경제</div>
      <div onClick={changeHigh} className='news_side_btn'>사회</div>
      <div onClick={changeHigh} className='news_side_btn'>생활/문화</div>
      <div onClick={changeHigh} className='news_side_btn'>IT/과학</div>
      <div onClick={changeHigh} className='news_side_btn'>세계</div>
      <div onClick={changeHigh} className='news_side_btn'>랭킹</div>
    </div>
  );
}

export default NewsSide;