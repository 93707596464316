import '../styles/Shop.css';

import { review_input, review_keyword, review_result, review_result_menus } from '../content/review_result';

import result_pic_1 from '../images/result_pic/1.png';
import result_pic_2 from '../images/result_pic/2.png';
import result_pic_3 from '../images/result_pic/3.png';
import result_pic_4 from '../images/result_pic/4.png';
import result_pic_5 from '../images/result_pic/5.png';

import result_menus_1 from '../images/result_menus/1.jpg';
import result_menus_2 from '../images/result_menus/2.jpg';
import result_menus_3 from '../images/result_menus/3.jpg';
import result_menus_4 from '../images/result_menus/4.jpg';
import result_menus_5 from '../images/result_menus/5.jpg';
import result_menus_6 from '../images/result_menus/6.jpg';

import result_reviews_1 from '../images/result_reviews/1.png';
import result_reviews_2 from '../images/result_reviews/2.png';
import result_reviews_3 from '../images/result_reviews/3.png';
import result_reviews_4 from '../images/result_reviews/4.png';
import result_reviews_5 from '../images/result_reviews/5.png';
import result_reviews_6 from '../images/result_reviews/6.png';

const result_pic = [result_pic_1, result_pic_2, result_pic_3, result_pic_4, result_pic_5];
const result_menus = [result_menus_1, result_menus_2, result_menus_3, result_menus_4, result_menus_5, result_menus_6];
const result_reviews = [result_reviews_1, result_reviews_2, result_reviews_3, result_reviews_4, result_reviews_5, result_reviews_6];

function Shop({ icons, iconsFunc }) {
  return (
    <div className='Shop'>
      <div id='shop_img'>
        {
          result_pic.map((e,i) => {
            return (
              <div key={"result_pic" + i} className='shop_img'>
                <img src={e} alt='shop_img' />
              </div>
            )
          })
        }
      </div>

      <div id='shop_name'>
        <div id='shop_name_main'>
          {review_input}
          <span id='shop_name_span'>브런치</span>
        </div>
        <div id='shop_name_bot'>
          <div id='shop_star'>
            <icons.FullStar />
          </div>
          <div id='shop_score'>4.79</div>
          <div className='shop_name_dot'>ㆍ</div>
          <div className='shop_name_bot_t'>방문자리뷰 1,534</div>
          <div className='shop_name_dot'>ㆍ</div>
          <div className='shop_name_bot_t'>블로그 1,534</div>
        </div>
      </div>

      <div id='shop_btn'>
        <div className='shop_btn shop_right_border'>
          <div className='shop_btn_icon'>
            <icons.Arrow />
          </div>
          <div className='shop_btn_t'>길찾기</div>
        </div>
        <div className='shop_btn shop_right_border'>
          <div className='shop_btn_icon'>
            <icons.Navi />
          </div>
          <div className='shop_btn_t'>거리뷰</div>
        </div>
        <div className='shop_btn shop_right_border'>
          <div className='shop_btn_icon'>
            <icons.Star />
          </div>
          <div className='shop_btn_t'>저장</div>
        </div>
        <div className='shop_btn'>
          <div className='shop_btn_icon'>
            <icons.Share />
          </div>
          <div className='shop_btn_t'>공유</div>
        </div>
      </div>

      <div id='shop_desc'>
        <div className='shop_desc mb19'>
          <div className='shop_desc_icon'>
            <icons.Cur />
          </div>
          <div className='shop_desc_t textUnder'>지도에서 보기</div>
        </div>
        <div className='shop_desc mb19'>
          <div className='shop_desc_icon'>
            <icons.Clock />
          </div>
          <div className='shop_desc_t'>영업 중 ㆍ 19:45에 라스트오더</div>
        </div>
        <div className='shop_desc mb19'>
          <div className='shop_desc_icon'>
            <icons.Phone />
          </div>
          <div className='shop_desc_t textUnder'>전화하기</div>
        </div>
        <div className='shop_desc mb19'>
          <div className='shop_desc_icon'>
            <icons.Shop />
          </div>
          <div className='shop_desc_t'>단체 이용 가능, 주차, 포장, 배달, 예약, 무선 인터넷, 남/녀 화장실 구분</div>
        </div>
        <div className='shop_desc'>
          <div className='shop_desc_icon'>
            <icons.Pay />
          </div>
          <div className='shop_desc_t'>{"지역화폐 (카드), 제로페이"}</div>
        </div>
      </div>

      <div id='shop_menu'>
        <div className='shop_header'>
          <div className='shop_header_1'>메뉴</div>
          <div className='shop_header_2'>더보기</div>
        </div>
        <div id='shop_menu_arr'>
          {
            review_result_menus.map((e,i) => {
              return (
                <div key={"reivew_result_menus" + i} className='shop_menu'>
                  <div className='shop_menu_img'>
                    <img src={result_menus[e.i - 1]} alt='review_result_menus' />
                  </div>
                  <div className='shop_menu_name'>{e.n}</div>
                  <div className='shop_menu_v'>
                    {e.v}
                    <span className='shop_menu_v_won'>원</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>

      <div id='shop_reviews'>
        <div className='shop_header'>
          <div className='shop_header_1'>방문자 리뷰</div>
          <div className='shop_header_2'>더보기</div>
        </div>

        <div id='shop_reviews_title'>
          {
            review_keyword.map((e,i) => {
              return (
                <div key={"shop_reviews_title" + i} className='shop_reviews_title'>
                  <div className='shop_reviews_title_icons'>
                    {
                      iconsFunc(i)
                    }
                  </div>
                  <div className='shop_reviews_title_text'>{"\"" + e.n + "\""}</div>
                  <div className='shop_reviews_title_num'>{e.i}</div>
                </div>
              )
            })
          }
        </div>

        <div className='shop_reviews_user_chunk'>
          {
            review_result.slice(0,3).map((e,i) => {
              return (
                <div key={"shop_reviews_user" + i} className={i !== 2 ? 'shop_reviews_user mr8' : 'shop_reviews_user'}>
                  <div className='shop_reviews_user_box'>
                    
                    <div className='shop_reivews_user_info'>
                      <div className='shop_reviews_user_info_name'>{e.n}</div>
                      <div className='shop_reviews_user_info_box'>
                        <div className='sRUIB_t'>리뷰</div>
                        <div className='sRUIB_t_n'>{e.r}</div>
                        <div className='sRUIB_dot'>ㆍ</div>
                        <div className='sRUIB_t'>사진</div>
                        <div className='sRUIB_t_n'>{e.p}</div>
                      </div>
                    </div>

                    <div className='shop_reviews_user_text'>{e.t}</div>
                    <div className='shop_reviews_user_btn'><span>더보기</span>ㆍ<span>반응 남기기</span></div>
                  </div>
                  <div className='shop_reviews_user_img'>
                    <img src={result_reviews[i]} alt='shop_reviews_user_img' />
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className='shop_reviews_user_chunk'>
          {
            review_result.slice(3).map((e,i) => {
              return (
                <div key={"shop_reviews_user" + (i + 3)} className={i !== 2 ? 'shop_reviews_user mr8' : 'shop_reviews_user'}>
                  <div className='shop_reviews_user_box'>
                    
                    <div className='shop_reivews_user_info'>
                      <div className='shop_reviews_user_info_name'>{e.n}</div>
                      <div className='shop_reviews_user_info_box'>
                        <div className='sRUIB_t'>리뷰</div>
                        <div className='sRUIB_t_n'>{e.r}</div>
                        <div className='sRUIB_dot'>ㆍ</div>
                        <div className='sRUIB_t'>사진</div>
                        <div className='sRUIB_t_n'>{e.p}</div>
                      </div>
                    </div>

                    <div className='shop_reviews_user_text'>{e.t}</div>
                    <div className='shop_reviews_user_btn'><span>더보기</span>ㆍ<span>반응 남기기</span></div>
                  </div>
                  <div className='shop_reviews_user_img'>
                    <img src={result_reviews[i + 3]} alt='shop_reviews_user_img' />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      
      <div id='shop_more'>화이트 가든 더보기 →</div>
    </div>
  );
}

export default Shop;