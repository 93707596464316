const adv_list = [
  {
    name: `trityclinic.co.kr`,
    sub: `의1133533`,
    title: `신사역 트리티여성의원`,
    body: `산부인과전문의2명 (여성), 여성종합검진, 결혼전검진, 갱년기검진, 성병, 피임`,
    // img: ``,
  },
  {
    name: `royeon.kr`,
    sub: `의1111305`,
    title: `여의사, 로연산부인과의원`,
    body: `여의사, 임신, 수술, 당일예약, 성병질염치료, 사후피임처방, 여성검진, 야간진료`,
    // img: ``,
  },
  {
    name: `momosclinic.com`,
    sub: `의1115028`,
    title: `자궁탈출증, 모모스산부인과의원`,
    body: `수술시 경막외마취로 하기 때문에 금식없이 내원 당일날도 수술을 지향합니다`,
    // img: ``,
  },
  {
    name: `appleclinic.com`,
    sub: `의1199157`,
    title: `여의사진료, 애플여성의원`,
    body: `여성 산부인과전문의, 임신상담, 당일수술예약, 사후피임약처방, 종합검진, 성병치료, 여성성형, 야간진료`,
    // img: ``,
  },
  {
    name: `happyclinic-medi.com`,
    sub: `의1143554`,
    title: `여의사진료, 행복산부인과의원`,
    body: `여의사진료, 야간진료, 임신상담, 질환치료, 사후피임약처방, 여성검진, 산부인과 등`,
    // img: ``,
  },
];

const place_list = [
  [
    {
      title: `라온산부인과의원`,
      body: `10:00에 진료 시작`,
      place: [
        `여의사, 매일야간진료, 일요일진료`,
        `서울 관악구 신림동 ↓`,
        `방문자 리뷰 687 ㆍ 블로그 리뷰 562`,
        `산부인과전문의 2명`,
      ]
      // img: ``,
    },
    {
      title: `루비여성의원`,
      body: `09:00에 진료 시작`,
      place: [
        `여성전문의, 여성종합검진, 성병`,
        `서울 관악구 신림동 ↓`,
        `방문자 리뷰 299 ㆍ 블로그 리뷰 3`,
        `산부인과전문의 1명`,
      ]
      // img: ``,
    },
    {
      title: `신촌미앤산부인과의원`,
      body: `10:00에 진료 시작`,
      place: [
        ``,
        `서울 마포구 노고산동 ↓`,
        `방문자 리뷰 433 ㆍ 블로그 리뷰 2,089`,
        `산부인과전문의 1명`,
      ]
      // img: ``,
    },
    {
      title: `재사랑산부인과`,
      body: `09:00에 진료 시작`,
      place: [
        ``,
        `서울 마포구 서교동 ↓`,
        `방문자 리뷰 771 ㆍ 블로그 리뷰 1,960`,
        `산부인과전문의 3명`,
      ]
      // img: ``,
    },
    {
      title: `강남미스산부인과의원`,
      body: `10:00에 진료 시작`,
      place: [
        ``,
        `서울 강남구 도곡동 ↓`,
        `방문자 리뷰 569 ㆍ 블로그 리뷰 114`,
        `산부인과전문의 1명`,
      ]
      // img: ``,
    },
  ],
  [
    {
      title: `앤스산부인과의원 건대입구역`,
      body: `10:00에 진료 시작`,
      place: [
        `여의사, 공단검진, 수술상담`,
        `서울 광진구 화양동 ↓`,
        `방문자 리뷰 866 ㆍ 블로그 리뷰 1,007`,
        `산부인과전문의 3명`,
      ]
      // img: ``,
    },
    {
      title: `호림여성병원`,
      body: `10:00에 진료 시작`,
      place: [
        `임신상담, 당일수술예약, 종합검진`,
        `서울 강남구 신사동 ↓`,
        `방문자 리뷰 1,582 ㆍ 블로그 리뷰 1,579`,
        `산부인과전문의 2명`,
      ]
      // img: ``,
    },
    {
      title: `이시영산부인과의원`,
      body: `09:00에 진료 시작`,
      place: [
        ``,
        `서울 구로구 구로동 ↓`,
        `방문자 리뷰 2,954 ㆍ 블로그 리뷰 618`,
        `산부인과전문의 1명`,
      ]
      // img: ``,
    },
    {
      title: `서울아은여성의원ㆍ산부인과`,
      body: `10:00에 진료 시작`,
      place: [
        ``,
        `서울 강남구 논현동 ↓`,
        `방문자 리뷰 445 ㆍ 블로그 리뷰 984`,
        `산부인과전문의 2명`,
      ]
      // img: ``,
    },
    {
      title: `행복관악산부인과의원`,
      body: `09:00에 진료 시작`,
      place: [
        ``,
        `서울 관악구 봉천동 ↓`,
        `방문자 리뷰 2,998 ㆍ 블로그 리뷰 1,137`,
        `산부인과전문의 4명`,
      ]
      // img: ``,
    },
  ]
]

const adv_list_setting = `adv`;
const place_list_setting = `place`;

export {
  adv_list,
  adv_list_setting,
  place_list,
  place_list_setting,
}