import './MailSidebar.css';

import { icons } from '../../../common/IconsFunc';

// **
// * this is common sidebar component
// * Read the common style
// **

function MailSidebar({ inbox }) {
  return (
    <div className="CommonSidebar">
      <div className="line2"></div>

      <div className="main_btn">
        <span>
          <icons.Pen />
        </span>
        <div className="t">Compose</div>
      </div>

      <div className="line"></div>
      <div className="line2"></div>

      <div onClick={() => {window.location = '/mail'}} className='list selected'>
        <span>
          <icons.Inbox />
        </span>
        <div className='t'>Inbox</div>
        <div className="p">{inbox}</div>
      </div>

      <div className='line'></div>

      <div className='list'>
        <span>
          <icons.Star />
        </span>
        <div className='t'>Starred</div>
      </div>
      <div className='list'>
        <span>
          <icons.Clock />
        </span>
        <div className='t'>Snoozed</div>
      </div>
      <div className='list'>
        <span>
          <icons.Bookmark />
        </span>
        <div className='t'>Important</div>
      </div>

      <div className='line'></div>

      <div className='list'>
        <span>
          <icons.PaperPlane />
        </span>
        <div className='t'>Sent</div>
      </div>
      <div className='list'>
        <span>
          <icons.File />
        </span>
        <div className='t'>Drafts</div>
      </div>
      <div className='list'>
        <span>
          <icons.Label />
        </span>
        <div className='t'>Categories</div>
      </div>
      <div className='list'>
        <span>
          <icons.Down />
        </span>
        <div className='t'>More</div>
      </div>

      <div className='line'></div>

      <div className='list'>
        <span>
          <icons.Down />
        </span>
        <div className='t'>Labels</div>
      </div>
    </div>
  );
}

export default MailSidebar;