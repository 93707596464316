import './WebHard.css';

import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import WebHardHeader from './WebHardHeader/WebHardHeader';
import WebHardIntro from './WebHardIntro/WebHardIntro';

import LoginModal, { loginModalOn } from '../Common/LoginModal/LoginModal';
import WebHardHome from './WebHardHome/WebHardHome';

function WebHard() {
  const [searchParam, setSearchParams] = useSearchParams();
  const [isLogin, setLogin] = useState(false);

  const webHard_ref = useRef(null);

  useEffect(() => {
    document.title = "LEGOO DRIVE";

    const loginState = searchParam.get('user');
    if(loginState && loginState !== '#n') {
      setLogin(true);
    };
  }, [searchParam, isLogin]);

  return (
    <div ref={webHard_ref} className="WebHard">
      <WebHardHeader
        isLogin={isLogin}
        setSearchParams={setSearchParams}
      />

      <LoginModal />

      <div id='modal_chroma_full'>
        <img src='/images/chroma_gray.png' alt='chroma' />
      </div>
      
      {
        isLogin ?
        <WebHardHome />
        :
        <WebHardIntro loginModalOn={loginModalOn} />
      }
    </div>
  );
}

export default WebHard;